import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Layout from "../components/Layout"
import SiteImage from "../components/SiteImage"
import SEO from "../components/SEO"

function NewsTemplate({ pageContext: { data } }) {
  const { strapiSiteMetadata } = useStaticQuery(graphql`
    {
      strapiSiteMetadata {
        siteUrl
      }
    }
  `)

  let jsonLDSchema = JSON.parse(data.seo.jsonLDSchema)
  const image = data.images[0]
  if (jsonLDSchema && data.images.length > 0) {
    jsonLDSchema = {
      ...jsonLDSchema,
      image:
        image.ext === ".svg"
          ? image.localFile.url
          : `${strapiSiteMetadata.siteUrl}${image.localFile.childImageSharp.gatsbyImageData.images.fallback.src}`,
    }
  }
  jsonLDSchema = JSON.stringify(jsonLDSchema)
  return (
    <Layout showNewsletterCTA>
      <SEO
        title={data.seo.title}
        description={data.seo.description}
        keywords={data.seo.keywords}
        jsonLDSchema={jsonLDSchema}
        pathname={`${data.news_category.slug}/${data.slug}`}
      />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-7">
              <h1 className="title is-1 my-6">{data.title}</h1>
              <div className="my-6">
                <span className="has-text-warning mr-3">
                  {data.news_category.title}
                </span>
                <span className="has-text-primary mr-3">
                  {data.author.name}
                </span>
                <span>
                  {new Date(data.publishedAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </span>
              </div>
              <Swiper
                autoHeight
                modules={[Pagination]}
                pagination={{
                  clickable: true,
                }}
              >
                {data.images.map((image, i) => (
                  <SwiperSlide key={i}>
                    <SiteImage image={image} alt="" />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="content my-6">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {data.content.data.content}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NewsTemplate
